.bg-light-primary {
	background-color: rgb(13 110 253 / 0.11) !important;
  }
  
  .bg-light-success {
	background-color: rgb(23 160 14 / 0.11) !important;
  }
  
  .bg-light-success2 {
    background-color: rgb(23 160 14 / 0.4) !important;
  }

  .bg-light-danger {
	background-color: rgb(244 17 39 / 0.11) !important;
  }
  
  .bg-light-warning {
	background-color: rgb(255 193 7 / 0.11) !important;
  }

  .bg-light-warning2 {
    background-color: rgb(255 193 7 / 0.4) !important;
    }
  
  .bg-light-info {
	background-color: rgb(13 202 240 / 18%) !important;
  }
  
  .bg-gradient-cosmic {
	background: linear-gradient(to right, #8e2de2, #4a00e0) !important;
  }
  
  .bg-gradient-burning {
	background: linear-gradient(to right, #ff416c, #ff4b2b) !important;
  }
  
  .bg-gradient-lush {
	background: linear-gradient(to right, #56ab2f, #a8e063) !important;
  }
  
  .bg-gradient-kyoto {
	background: linear-gradient(to right, #f7971e, #ffd200) !important;
  }
  
  .bg-gradient-blues {
	background: linear-gradient(to right, #56ccf2, #2f80ed) !important;
  }
  
  .bg-gradient-moonlit {
	background: linear-gradient(to right, #0f2027, #203a43, #2c5364) !important;
  }

  .bg-gradient-spa {
    background: linear-gradient(to right, #ab2fa5, #e063c9) !important;
  }
  
  .split-bg-primary {
	background-color: #0c62e0;
	border-color: #0c62e0;
  }
  
  .split-bg-secondary {
	background-color: #515a62;
	border-color: #515a62;
  }
  
  .split-bg-success {
	background-color: #128e0a;
	border-color: #128e0a;
  }
  
  .split-bg-info {
	background-color: #0bb2d3;
	border-color: #0bb2d3;
  }
  
  .split-bg-warning {
	background-color: #e4ad07;
	border-color: #e4ad07;
  }
  
  .split-bg-danger {
	background-color: #e20e22;
	border-color: #e20e22;
  }
  
  .bg-facebook {
	background-color: #3b5998 !important;
  }
  
  .bg-twitter {
	background-color: #55acee !important;
  }
  
  .bg-google {
	background-color: #e52d27 !important;
  }
  
  .bg-linkedin {
	background-color: #0976b4 !important;
  }