@import '@angular/cdk/overlay-prebuilt.css';
@import 'node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base';
@import '../../../../../node_modules/angular-calendar/scss/angular-calendar';

  @include cal-theme(
    (
      bg-primary: #fff,
      bg-secondary: #fafafa,
      bg-active: #ededed,
      event-color-primary: #fb6018,
      event-color-secondary: #ecfb18,
      border-color: #e1e1e1,
      today-bg: #d63384,
      weekend-color: #d63384,
      badge-color: #6c757d,
      current-time-marker-color: #fff,
      white: #fff,
      gray: #555,
      black: #000
    ));

  // How to override a non themable property, this was copied from chrome -> inspect element -> styles panel
  .cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.15;
  }

.dark-theme {
  // Call the calendar mixin with a sass color map of your theme. Every property is optional.
  // For a list of all variables and how they are used,
  // see https://github.com/mattlewis92/angular-calendar/tree/main/projects/angular-calendar/src/variables.scss
  @include cal-theme(
    (
      bg-primary: #1f262d,
      bg-secondary: #394046,
      bg-active: #2c343a,
      event-color-primary: #fb6018,
      event-color-secondary: #ecfb18,
      border-color: rgb(0 0 0 / 60%),
      today-bg: #d63384,
      weekend-color: #d63384,
      badge-color: #6c757d,
      current-time-marker-color: #fff,
      white: #fff,
      gray: #555,
      black: #000
    ));

  // How to override a non themable property, this was copied from chrome -> inspect element -> styles panel
  .cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.15;
  }
}

.month-cell-fill-height {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}