/* Toastr */
.toast-success {
  background-color: #2c832f !important;
  color: #b4f1b6 !important;
}
.toast-info {
  background-color: #1965a3 !important;
  color: #bfe0fa !important;
}
.toast-warning {
  background-color: #ffc107 !important;
  color: #252525 !important;
}
.toast-error {
  background-color: #ab1a10 !important;
  color: #ffcbc9 !important;
}
.toast-top-right {
  top: 60px !important;
}